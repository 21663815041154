
import { Text, TextToStr } from '../languages/Language';
import {roundDigits} from "../utils";
import { getCurrency, getDecimalDigits, IMAGEURL, PAYMENTSYSTEM } from '../constants';
import OrderItems from "./OrderItems";
import React, { useEffect, useState } from "react";
import { Stepper, Step } from "react-form-stepper";
import StepWizard from "react-step-wizard";
import { Row, Col, Button, FormGroup, Label, Input } from "reactstrap";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faAddressCard, faCheck, faCreditCard, faShoppingCart } from "@fortawesome/free-solid-svg-icons";
import StripeTest from '../stripe/test/Stripe';



function DetailedBasket(props) {
  const [stepWizard, setStepWizard] = useState(null);
  const [activeStep, setActiveStep] = useState(0);
  const [activeTab, setActiveTab] = useState('creditCard');
  const [errorModal, setErrorModal] = useState(false);
  const [errorText, setErrorText] = useState("");
  const [data, setData] = useState(null);
  const [showSuccessNotification, setShowSuccessNotification] = useState(false);
  const [orderNo, setOrderNo] = useState("");


  const ActionButtons = (propsi) => {
    const handleBack = () => {
      propsi.previousStep();
    };

    const handleNext = () => {
      propsi.nextStep();
    };

    const handleFinish = () => {
      propsi.lastStep();
    };

    return (
      <div className="flex flex-row justify-center gap-x-2">
          {(propsi.currentStep > 1 && PAYMENTSYSTEM) && (
              <Button onClick={handleBack} className="p-1 min-w-[8rem] text-center bg-company-primary-light hover:bg-orange-300 text-white rounded-md"><Text tid="Previous"/></Button>
          )}
          <Row>
            {(propsi.currentStep < propsi.totalSteps && PAYMENTSYSTEM) && (
              <Button onClick={handleNext} className="p-1 min-w-[8rem] text-center bg-company-primary-color hover:bg-orange-300 text-white rounded-md"><Text tid="Next"/></Button>
            )}
            {(propsi.currentStep === propsi.totalSteps || !PAYMENTSYSTEM) && (
              <OrderItems
              cartItems={props.cartItems}
              onSlipDeleteAllLines={() => {
                props.onSlipDeleteAllLines();
                props.ShowDetailedBasket(false);

                handleDelete();
              }}
              errorModal ={(bool)=> setErrorModal(bool)}
              errorText ={(text)=> setErrorText(text)}
              showToast={(type) => props.showToast(type)}
              showSuccess = {(bool)=> setShowSuccessNotification(bool)}
              orderNo = {(text)=> setOrderNo(text)}
              activeStep = {(int)=> setActiveStep(int)}
            />
            )}
          </Row>
      </div>
    );
  };


  const assignStepWizard1 = (instance) => {
    setStepWizard(instance);
  };

  const handleStepChange1 = (e) => {
    setActiveStep(e.activeStep - 1);
  };

  const handleComplete1 = () => {
    alert("You r done. TQ");
  };


    const discRate = sessionStorage.getItem('discRate');
    const itemsPrice = props.cartItems?.reduce((a, c) =>
        a + c.itemMainUnitVpe
        * (c.itemCampaignPrice ? c.itemCampaignPrice?.toFixed(getDecimalDigits()) : c.itemPrice?.toFixed(getDecimalDigits()))
        * (c.itemCampaignPrice ? 1 : (1 - (!Number.isNaN(discRate) && discRate > 0 ? discRate / 100 : 0)))
        * c.itemAmount, 0);

    const itemsPriceTax = props.cartItems?.reduce((a, c) =>
        a + c.itemMainUnitVpe
        * (c.itemCampaignPrice ? c.itemCampaignPrice?.toFixed(getDecimalDigits()) : c.itemPrice?.toFixed(getDecimalDigits()))
        * (c.itemCampaignPrice ? 1 : (1 - (!Number.isNaN(discRate) && discRate > 0 ? discRate / 100 : 0)))
        * (1 + c.itemVat / 100)
        * c.itemAmount, 0);

   // const [showModalForItem, setShowModalForItem] = React.useState(false);
    const [Expl1, setExpl1] = React.useState('');
    const [Expl2, setExpl2] = React.useState('');

    React.useEffect(() => {
        const storedExpl1 = sessionStorage.getItem('Expl1');
        const storedExpl2 = sessionStorage.getItem('Expl2');
        setData(JSON.parse(sessionStorage.getItem('user')));
        if (storedExpl1) {
            setExpl1(storedExpl1);
        }
        if (storedExpl2) {
            setExpl2(storedExpl2);
        }
    }, [sessionStorage.getItem('Expl1'), sessionStorage.getItem('Expl2'),sessionStorage.getItem('user')]);

    const handleChangeExpl1 = (event) => {
        setExpl1(event.target.value);
        sessionStorage.setItem('Expl1', event.target.value);
    };

    const handleChangeExpl2 = (event) => {
        setExpl2(event.target.value);
        sessionStorage.setItem('Expl2', event.target.value);
    };

    const handleDelete = () => {
        sessionStorage.removeItem('Expl1');
        sessionStorage.removeItem('Expl2');
        setExpl1('');
        setExpl2('');
    };

  const validate = () => {
      stepWizard.nextStep();
  };

  const validate2 = () => {
      stepWizard.previousStep();
  };

  const validate3 = () => {
    props.ShowDetailedBasket(false)
    setActiveStep(0)
      stepWizard.lastStep();
  };

  const transit = {
    enter: '',
    exit: ''
  };

  var table = (<div className='pt-4' >
    <div className=' flex flex-col lg:flex-row items-center px-4 pb-3 gap-1'>
                                        <div className='flex flex-row w-full items-center justify-evenly'>
                              <label
                                  htmlFor="Explanation1" className="text-sm items-center whitespace-nowrap overflow-hidden text-ellipsis"
                              >
                                  <Text tid="Explanation1" />:
                              </label>

                              <input
                                  id="Explanation1"
                                  name="Explanation1"
                                  type="text"
                                  value={Expl1}
                                  onChange={handleChangeExpl1}
                                  autoComplete="Explanation1"
                                  maxLength={51}
                                  className="appearance-none mx-1 rounded w-3/5 sm:w-3/4 h-8 border"
                              />
                          </div>
                          <div  className='flex flex-row w-full items-center justify-evenly'>
                              <label
                                  htmlFor="Explanation2" className="text-sm items-center whitespace-nowrap overflow-hidden text-ellipsis"
                              >
                                  <Text tid="Explanation2" />:
                              </label>

                              <input
                                  id="Explanation2"
                                  name="Explanation2"
                                  type="text"
                                  value={Expl2}
                                  onChange={handleChangeExpl2}
                                  autoComplete="Explanation2"
                                  maxLength={51}
                                  className="appearance-none mx-1 rounded  w-3/5 sm:w-3/4 h-8 border"
                              />
                          </div>
                      </div>

    <div className="px-4 flex justify-center">
    <table className="overflow-auto w-full  md:text-lg table-auto text-slate-600">
        <thead className=" bg-white sticky top-0 font-thin">
          <tr className="text-xs text-start text-white bg-company-primary-color">

            <th className="font-normal text-start hidden md:block px-0.5">
              <Text tid="ProductCode" />
            </th>
            <th className="font-normal text-start px-1">
              <Text tid="ProductName" />
            </th>
            <th className="font-normal text-end">
              <Text tid="ProductCount" />
            </th>
            <th className="font-normal text-center">
            <Text tid="Unit" />
            </th>
            <th className="font-normal text-end">
            <Text tid="PackagingUnit" />
            </th>
            <th className="font-normal text-end">
            <Text tid="PiecePrice" />
            </th>
            <th className="font-normal text-end hidden md:block">
              <Text tid="NetAmount" />
            </th>
            <th></th>
            <th className="font-normal text-end hidden md:block px-1">
              <Text tid="VAT" />
            </th>

          </tr>
        </thead>
        <tbody className="overflow-y-auto">
          {props.cartItems
            ? props.cartItems.map((record, index) => {
                return (
                  <tr key={index} className="odd:bg-white even:bg-slate-200">

                    <td className="text-xs hidden md:block px-0.5">{record.itemCode}</td>

                    <td className="text-start text-xs px-1">{record.itemName1}</td>

                    <td className='text-xs text-end'>
                    {record.itemAmount}
                    </td>

                    <td className='text-xs text-center'>
                    {record.itemUnitCode}
                    </td>

                    <td className='text-xs text-end'>
                    {record.itemMainUnitVpe}
                    </td>

                    {/* Piece Price */}
                    <td className="text-xs text-end">
                      {record.itemCampaignPrice > 0 ? (
                        <>
                          <span className="line-through mr-1">
                            {roundDigits(record.itemPrice.toFixed(getDecimalDigits()))} {getCurrency()}
                          </span>
                          <span>
                            {roundDigits(record.itemCampaignPrice.toFixed(getDecimalDigits()))} {getCurrency()}
                          </span>
                        </>
                      ) : (
                        <span>
                          {roundDigits(record.itemPrice.toFixed(getDecimalDigits()))} {getCurrency()}
                        </span>
                      )}
                    </td>

                    {/* Net Amount */}
                    <td className='text-xs text-end hidden md:block'>
                      {roundDigits(
                        record.itemMainUnitVpe *
                          (record.itemCampaignPrice ? 1 : 1 - discRate / 100) *
                          record.itemAmount *
                          (record.itemCampaignPrice
                            ? record.itemCampaignPrice.toFixed(getDecimalDigits())
                            : record.itemPrice.toFixed(getDecimalDigits()))
                      )}
                      &nbsp;{getCurrency()}
                    </td>

                    <td></td>

                    {/* VAT */}
                    <td className='text-xs text-end hidden md:block px-1'>{record.itemVat}%</td>

                  </tr>
                );
              })
            : null}
        </tbody>
      </table>
    </div>

    <div className='flex flex-grow'></div>

    <div className="flex text-xs w-full flex-col-reverse lg:flex-row p-4 lg:justify-between sticky bottom-0 bg-white">
      <div className="text-left mt-2 sm:mt-0">
        <p className="font-bold">{data&&data[0].erpCustomerName1}</p>
        <p>{data&&data[0].erpShippingAdrName2} {data&&data[0].erpShippingAdrAddress1} {data&&data[0].erpShippingAdrAddress2} {data&&data[0].erpShippingAdrPostalCode} {data&&data[0].erpShippingAdrCityName} {data&&data[0].erpShippingAdrCountryCode} {data&&data[0].erpShippingAdrCountryName}</p>

      </div>

      <div className="flex text-xs justify-end ">
        <table>
          <tr className="justify-between">
            <td className="pr-8"><Text tid="NetAmount" /></td>
            <td className="w-6">:</td>
            <td className="text-right">{roundDigits(itemsPrice?.toFixed(getDecimalDigits()))}&nbsp;{getCurrency()}</td>
          </tr>
          <tr className="justify-between">
            <td><Text tid="VAT" /></td>
            <td>:</td>
            <td className="text-right">{roundDigits((itemsPriceTax - itemsPrice)?.toFixed(getDecimalDigits()))}&nbsp;{getCurrency()}</td>
          </tr>
          <tr className="justify-between">
            <td className="font-bold"><Text tid="TotalAmount" /></td>
            <td>:</td>
            <td className="font-bold text-right">{roundDigits(itemsPriceTax?.toFixed(getDecimalDigits()))}&nbsp;{getCurrency()}</td>
          </tr>
        </table>
        </div>
    </div>
    </div>);

    return (
        <>
          {props.detailedBasket && sessionStorage.getItem("token") ? (
            <>
              <div className="text-slate-600  fade In justify-center items-center flex overflow-hidden fixed inset-0 z-50 outline-none focus:outline-none">
                <div className="flex w-11/12 md:w-5/6 xl:w-3/5 my-auto mx-auto h-[90%] max-h-[600px]">
                  {/*content*/}
                  <div className="overflow-auto border-0 rounded-lg shadow-lg relative flex flex-col w-full h-full bg-white p-2">
                    {/*header*/}
                    <div className="flex flex-col">
                    <div className="sticky top-0 flex flex-row gap-3 justify-between p-1 border-b border-solid border-slate-200 rounded-t w-full h-18 z-10">
                      <button
                        className="border-0 text-black  flex items-center h-6 opacity-50 text-3xl font-semibold outline-none focus:outline-none"
                        onClick={() => {
                          props.ShowDetailedBasket(false)
                          setActiveStep(0)
                        }}
                      >
                        <span className="bg-transparent text-black hover:text-gray-500 h-6 w-6 text-xl flex items-centers justify-center outline-none focus:outline-none">
                          &#10006;
                        </span>
                      </button>
                      <h3 className="text-lg font-semibold text-slate-700 h-10 mr-auto">
                        <Text tid="ShoppingCart" />
                      </h3>

                      <div>
                      <ActionButtons currentStep={activeStep+1} totalSteps={3} nextStep={validate} previousStep={validate2} lastStep={validate3} />

                      </div>


                    </div>
                    <div className={`${!PAYMENTSYSTEM?"hidden":""}`}>
                    <Stepper activeStep={activeStep}>
                      <Step children={<FontAwesomeIcon icon={faAddressCard}></FontAwesomeIcon>} />
                      <Step children={<FontAwesomeIcon icon={faCreditCard}></FontAwesomeIcon>} />
                      <Step children={<FontAwesomeIcon icon={faCheck}></FontAwesomeIcon>} />
                    </Stepper>
                    </div>
                    </div>
                    {/*body*/}
                    <div className="h-full overflow-y-auto overflow-x-hidden">

                {/* NOTE: IMPORTANT !! StepWizard must contains at least 2 children components, else got error */}

                {PAYMENTSYSTEM && (

                  <StepWizard transitions={transit} instance={assignStepWizard1} onStepChange={handleStepChange1}>


                            {/*Adres Ekranı */}
                            <div>
                              <div className="h-64 flex justify-center">

                                <div className="p-4">
                                  <h2 className="text-xl font-bold mb-4 ml-4">Gönderim Adresi</h2>


                                  <div className="mt-4 p-4 border">
                                    {data && data.length > 0 && data[0].erpShippingAdrAddress1 && (
                                      data[0].erpShippingAdrAddress1 !== null ? (
                                        <div>
                                          <p className="font-bold">{data&&data[0].erpCustomerName1}</p>
                                          <p>{data&&data[0].erpCustomerAddress1}</p>
                                          <p>{data&&data[0].erpCustomerCityPostalCode}</p>
                                          <p>{data&&data[0].erpCustomerCityName}</p>
                                          <p>{data&&data[0].erpCustomerCountryName}</p>
                                        </div>
                                      ) : (
                                        <div>
                                          <p className="font-bold">{data&&data[0].erpShippingAdrName1}</p>
                                          <p>{data&&data[0].erpShippingAdrName2}</p>
                                          <p>{data&&data[0].erpShippingAdrAddress1}</p>
                                          <p>{data&&data[0].erpShippingAdrAddress2}</p>
                                          <p>{data&&data[0].erpShippingAdrPostalCode}</p>
                                          <p>{data&&data[0].erpShippingAdrCityName}</p>
                                          <p>{data&&data[0].erpShippingAdrCountryCode}</p>
                                          <p>{data&&data[0].erpShippingAdrCountryName}</p>
                                        </div>
                                      )
                                    )}
                                  </div>


                                </div>

                              </div>
                              </div>

                            {/*Ödeme Ekranı */}
                            <div>

                              <div className="h-64 w-full flex justify-center">
                              <div className="p-4 w-3/5">
                              <h2 className="text-center w-full text-xl font-bold mb-4">Ödeme Yöntemi Seçiniz</h2>

                              <StripeTest  amount={Math.round(itemsPriceTax * 100)}></StripeTest>
                                </div>
                              {/*
                              <StripeModal amount={Math.round(itemsPriceTax * 100) / 100}></StripeModal>
                              */}
                              </div>

                              </div>

                            {/*Özet Ekranı */}
                            {table}


                            </StepWizard>)}

                {!PAYMENTSYSTEM && (
                            table)}


                      </div>

                  </div>
                </div>
              </div>
              <div className="opacity-50 fixed inset-0 z-40 bg-black"></div>
            </>
          ) : null}




{errorModal?
          <>
          <div
              className="justify-center items-center flex overflow-x-hidden
overflow-y-auto fixed inset-0 z-50 outline-none focus:outline-none"
          >
              <div
                  className="relative w-auto my-6 mx-auto max-w-3xl"
              >
                  {/*content*/}
                  <div
                      className="border-0 rounded-lg shadow-lg relative flex flex-col w-full bg-white outline-none focus:outline-none"
                  >
                      {/*header*/}
                      <div
                                className=" flex items-start text-center justify-center p-5 border-b border-solid border-slate-200 rounded-t"
                            >
                                <h3
                                    className="text-lg font-semibold text-center"
                                >
                                    <Text tid="Error" />
                                </h3>

                            </div>
                      {/*body*/}
                      <div
                          className="min-w-[15rem] relative p-6 flex-auto"
                      >
                          <div
                              className="text-center">
                              {errorText}
                              <div className='flex flex-row justify-center mt-6'>
                                  <button
                                  onClick={() => setErrorModal(false)}
                                      className="flex min-w-[8rem] items-center justify-center rounded-md border border-transparent bg-company-primary-color px-3 py-1 text-base font-medium text-white shadow-sm hover:opacity-75"
                                      type="button"

                                  >
                                      <Text tid="Ok" />
                                  </button>

                              </div>
                          </div>
                      </div>
                  </div>
              </div>
          </div>
          <div
              className="opacity-50 fixed inset-0 z-40 bg-black"
          >
          </div>
      </>
        :null}

            {
        showSuccessNotification && (
          <>
          <div
              className="justify-center items-center flex overflow-x-hidden
overflow-y-auto fixed inset-0 z-50 outline-none focus:outline-none"
          >
              <div
                  className="relative w-auto my-6 mx-auto max-w-3xl"
              >
                  {/*content*/}
                  <div
                      className="border-0 rounded-lg shadow-lg relative flex flex-col w-full bg-white outline-none focus:outline-none"
                  >
                      {/*header*/}
                      <div
                                className=" flex items-start text-center justify-center p-5 border-b border-solid border-slate-200 rounded-t"
                            >
                                <h3
                                    className="text-lg font-semibold text-center"
                                >
                                    <Text tid="Successful" />
                                </h3>

                            </div>
                      {/*body*/}
                      <div
                          className="relative p-6 flex-auto"
                      >
                          <div
                              className="text-center">
                              <Text tid="OrderSuccess"/> {orderNo}
                              <div className='flex flex-row justify-center mt-6'>
                                  <button
                                  onClick={() => setShowSuccessNotification(false)}
                                      className="flex min-w-[8rem] items-center justify-center rounded-md border border-transparent bg-company-primary-color px-3 py-1 text-base font-medium text-white shadow-sm hover:opacity-75"
                                      type="button"

                                  >
                                      <Text tid="Ok" />
                                  </button>

                              </div>
                          </div>
                      </div>
                  </div>
              </div>
          </div>
          <div
              className="opacity-50 fixed inset-0 z-40 bg-black"
          >
          </div>
      </>
        )
      }
        </>
      );

}

export default DetailedBasket