import React from 'react'
import { Text } from '../languages/Language';

export default function Datenschutz(props) {

    function openDatenschutz() {
        props.ShowDatenschutz(true)

    };


    return (
        <>
            <button
                className="flex items-center
        justify-center "
                type="button"
                onClick={() => openDatenschutz()}
            >
                <Text tid="PrivacyPolicy" />
            </button>

        </>
    )
}